import React from 'react'

import { createRoot } from 'react-dom/client'

import 'bootstrap'

import App from './pages/navigation/App'
import './variables.scss'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
