import React from 'react'

export default function Input ({ onChange, required, children, placeholder, id, ...rest }) {
	return (
		<div className='mb-3'>
			<label
				className={`${required ? 'required' : ''}`}
				htmlFor={`${children}`}
			>
				{children}
			</label>
			<input
				{...rest}
				required={required}
				onChange={({ target: { value } }) => onChange(value)}
				className='shadow-sm form-control'
				placeholder={placeholder || children}
			/>
		</div>
	)
}
