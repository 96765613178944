import React, { useState } from 'react'

import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { auth } from '../../firebase'

import Input from '../../components/Input'
import Alert from '../../components/Alert'

export default function New ({ update }) {
	const [{ displayName, email, password }, setUser] = useState({
		displayName: '',
		email: '',
		password: ''
	})
	const [{ message, state }, setMessage] = useState({
		message: '',
		state: ''
	})

	return (
		<form
			onSubmit={async event => {
				event.preventDefault()
				try {
					const response = await createUserWithEmailAndPassword(
						auth,
						email,
						password
					)

					await updateProfile(response.user, {
						displayName
					})
					update(displayName)
				} catch (error) {
					setMessage({
						state: 'FAIL',
						message: 'Benutzername oder Passwort ist falsch.'
					})
				}
			}}
		>
			<Input
				required
				value={displayName}
				onChange={displayName => setUser(x => ({ ...x, displayName }))}
			>
                Benutzername
			</Input>
			<Input
				required
				value={email}
				onChange={email => setUser(x => ({ ...x, email }))}
				type='email'
			>
                E-Mail
			</Input>
			<Input
				required
				value={password}
				onChange={password => setUser(x => ({ ...x, password }))}
				type='password'
				pattern='.{6,}'
			>
                Passwort
			</Input>
			<Alert state={state}>{message}</Alert>
			<div className='d-grid gap-2'>
				<button
					type='submit'
					className='btn btn-primary shadow-sm'
				>
                    Registrieren
				</button>
			</div>
		</form>
	)
}
