import React, { useState } from 'react'
import { auth } from '../../firebase'
import { Link } from 'react-router-dom'

import {
	browserSessionPersistence,
	setPersistence,
	signInWithEmailAndPassword
} from 'firebase/auth'
import Input from '../../components/Input'
import Alert from '../../components/Alert'

export default function Login () {
	const [{ email, password }, setLogin] = useState({
		email: '',
		password: ''
	})
	const [{ message, state }, setError] = useState({ message: '', state: '' })

	return (
		<form
			onSubmit={async event => {
				event.preventDefault()
				try {
					await setPersistence(auth, browserSessionPersistence)
					await signInWithEmailAndPassword(auth, email, password)
				} catch (error) {
					setError({
						state: 'FAIL',
						message: 'Benutzername oder Passwort ist falsch.'
					})
				}
			}}
		>
			<Input
				value={email}
				onChange={email => setLogin(x => ({ ...x, email }))}
				type='email'
			>
                E-mail Adresse
			</Input>
			<Input
				value={password}
				onChange={password => setLogin(x => ({ ...x, password }))}
				type='password'
			>
                Passwort
			</Input>
			<Alert state={state}>{message}</Alert>
			<div className='d-grid gap-2'>
				<button
					type='submit'
					className='btn btn-primary shadow-sm'
				>
                    Anmelden
				</button>
			</div>
			<div className='mb-3'>
				<Link
					className='p-1 badge text-dark bg-primary text-decoration-none'
					to='/register'
				>
                    Registrieren
				</Link>
			</div>
		</form>
	)
}
