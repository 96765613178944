import React from 'react'

export default function Impressum () {
	(
		<div>
			<h2>Kontaktdaten</h2>
			<address>
                Offene Jugendarbeit Gaiserwald
				<br />
                Hauptstrasse 21, 9030 Abtwil
				<br />
				<a
					className='p-1 badge text-dark bg-primary text-decoration-none'
					href='mailto:adriana.broennimann@gaiserwald.ch'
				>
                    remo.kittelmann@gaiserwald.ch
				</a>
				<br />
				<a
					className='p-1 badge text-dark bg-primary text-decoration-none'
					href='tel:+41 79 373 39 30'
				>
                    +41 79 373 39 30
				</a>
				<br />
			</address>

			<h2>Rechtliche Grundlagen</h2>
			<p>
                Die Hompage www.holidayjobs.ch dient nur zur Vermittlung von Ferien-
                und Taschengeldjobs für Jugendliche. Die Versicherung ist Sache der
                Arbeitnehmer/-innen. Die offene Jugendarbeit Gaiserwald lehnt
                jegliche Haftung ab.
			</p>
			<h2>Datenschutz</h2>
			<p>
                Das Benutzerkonto ist nur für die Benutzerin bzw. den Benutzer
                zugänglich. Die Jugendtreffmitarbeitenden haben jedoch die
                Möglichkeit alle Jobangebote zu bearbeiten oder zu löschen. Die
                Anmeldung erfolgt mit den persönlichen Profildaten (z.B.
                E-Mail/Mobile und Passwort). Bitte wählen Sie ein genügend starkes
                Passwort. Stellen Sie sicher, dass Ihr Passwort nicht in die Hände
                Dritter gelangt.
			</p>
		</div>
	)
}
