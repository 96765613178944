import React, { useEffect, useState } from 'react'
import Phone from 'react-feather/dist/icons/phone'
import Mail from 'react-feather/dist/icons/mail'
import Trash from 'react-feather/dist/icons/trash-2'
import Edit from 'react-feather/dist/icons/edit'
import { collection, query, getDocs, deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../firebase'

import { Link } from 'react-router-dom'

export default function All ({ uid }) {
	const [jobs, setJobs] = useState([])

	useEffect(() => {
		(async () => {
			const { docs } = await getDocs(query(collection(db, 'jobs')))
			const jobs = docs.map(doc => {
				const { id } = doc
				const data = doc.data()
				return { ...data, id }
			})

			setJobs(jobs)
		})()
	}, [])

	return (
		<div className='row row-cols-1 row-cols-md-2 g-4'>
			{jobs.map(
				({ id, creator, title, description, date, address, phoneNumber, email }) => (
					<div key={id} className='col'>
						<div className='card shadow-sm'>
							<div className='card-body'>
								<h5 className='card-title'>{title}</h5>
								<div className='container-fluid p-0'>
									<div className='row row-cols-1'>
										<div className='col'>{description}</div>
										<div className='col'>
											<small className='text-muted'>
                                                Wann: {date}
											</small>
										</div>
										<div className='col'>
											<small className='text-muted'>
                                                Wo: {address}
											</small>
										</div>
									</div>
								</div>
							</div>

							<div className='card-footer d-flex justify-content-between'>
								<div className='btn-group' role='group'>
									{phoneNumber && (
										<a
											className='btn btn-primary btn-sm'
											href={`tel:${phoneNumber}`}
										>
											<Phone size={12} />
										</a>
									)}
									{email && (
										<a
											className='btn btn-primary btn-sm'
											href={`mailto:${email}`}
										>
											<Mail size={12} />
										</a>
									)}
								</div>
								<div
									className='btn-group justify-content-end'
									role='group'
								>
									{uid === creator && (
										<Link
											className='btn btn-primary btn-sm'
											to={`editjob/${id}`}
										>
											<Edit size={12} />
										</Link>
									)}
									{uid === creator && (
										<button
											className='ms-1 btn btn-primary btn-sm'
											onClick={async () => {
												await deleteDoc(doc(db, 'jobs', id))

												setJobs(jobs =>	jobs.filter(job => job.id !== id))
											}}
										>
											<Trash size={12} />
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				)
			)}
			{uid && (
				<div className='col-md-12 mb-2 col-lg-12 col-12 col-sm-12 col-xl-12'>
					<div className='card shadow-sm'>
						<div className='card-body'>
							<Link
								className='p-1 badge text-dark bg-primary text-decoration-none'
								to='createjob'
							>
                                Auftrag hinzufügen
							</Link>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
