import React, { useEffect, useState } from 'react'

import Input from '../../components/Input'
import Alert from '../../components/Alert'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../../firebase'

export default function Edit ({ uid, displayName }) {
	const [{ phoneNumber, address }, setSettings] = useState({
		displayName: '',
		phoneNumber: '',
		address: ''
	})
	const [{ state, message }, setAlert] = useState({ state: '', message: '' })
	useEffect(() => {
		(async () => {})()
	}, [])
	return (
		<form
			onSubmit={async event => {
				event.preventDefault()
				try {
					await setDoc(doc(db, 'users', uid), {
						phoneNumber,
						address
					})

					setAlert({
						message: 'Perfekt! Dein Profil wurde angepasst.',
						state: 'SUCCESS'
					})
				} catch (error) {
					setAlert({
						message:
                            'Das hat leider nicht geklappt. Bitte wenden Sie sich an den Jugendtreff.',
						state: 'FAIL'
					})
				}
			}}
		>
			<Input
				required
				value={displayName}
				onChange={displayName =>
					setSettings(x => ({ ...x, displayName }))
				}
				disabled
			>
                Anzeigename
			</Input>
			<Input
				required
				value={address}
				onChange={address => setSettings(x => ({ ...x, address }))}
			>
                Adresse
			</Input>
			<Input
				value={phoneNumber}
				onChange={phoneNumber =>
					setSettings(x => ({ ...x, phoneNumber }))
				}
				type='phoneNumber'
			>
                Telefon
			</Input>
			<Alert state={state}>{message}</Alert>
			<div className='d-grid gap-2'>
				<button
					type='submit'
					className='btn btn-primary shadow-sm'
				>
                    Ändern
				</button>
			</div>
		</form>
	)
}
