import React from 'react'

export default function Home () {
	return (
		<div>
			<p>
            holidayjobs ist die Plattform für die Vermittlung von Jobs und Services zwischen Personen. Wir bringen dich mit talentierten Dienstleistern zusammen, die dein Leben einfacher machen. Finde schnell und einfach die perfekte Person für deinen Bedarf oder werde selbst ein Dienstleistungsanbieter. Registriere dich jetzt und erlebe eine einfache und sichere Art, Dienstleistungen zu vermitteln.
			</p>
		</div>
	)
}
