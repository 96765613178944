import React, { useEffect, useState } from 'react'

import { BrowserRouter, Route, Routes, Navigate, Link } from 'react-router-dom'
import { auth } from '../../firebase'

import Logo from './logo.png'
import JobsPage from '../job/All'
import ImpressumPage from '../anonymous/Impressum'
import HomePage from '../anonymous/Home'
import LoginPage from '../anonymous/Login'
import CreateJobPage from '../job/New'
import RegisterPage from '../user/New'
import EditJobPage from '../job/Edit'
import SettingsPage from '../user/Edit'

import Navigation from './Navigation'
import Footer from './Footer'

const Container = ({ children }) => {
	const [firstChild, ...other] = children
	return (
		<div className='container'>
			<div className='row'>
				<div className='col-sm-12'>{firstChild}</div>
			</div>
			<div className='row'>
				<div className='col-sm-12'>{other}</div>
			</div>
		</div>
	)
}

const Impressum = () => (
	<Container>
		<h1>Impressum</h1>
		<ImpressumPage />
	</Container>
)

const Jobs = ({ uid }) => (
	<Container>
		<h1>Ferien- und Taschengeldjobs</h1>
		<h3>
			<small className='text-muted'>
                Registriere dich, wenn du einen Ferien- oder Taschengeldjob
                erstellen möchtest.
			</small>
		</h3>
		<JobsPage uid={uid} />
	</Container>
)
const CreateJob = ({ uid }) => (
	<Container>
		<h1>Ferien- und Taschengeldjobs erstellen</h1>
		<h3>
			<small className='text-muted'>
                Du kannst die Jobangaben jederzeit bearbeiten oder wieder
                löschen.
			</small>
		</h3>
		<CreateJobPage uid={uid} />
	</Container>
)
const EditJob = ({ uid }) => (
	<Container>
		<h1>Ferien- und Taschengeldjobs bearbeiten</h1>
		<EditJobPage uid={uid} />
	</Container>
)
const Register = ({ update }) => (
	<Container>
		<h1>Registrieren</h1>
		<h3>
			<small className='text-muted'>
                Registriere dich, wenn du einen Ferien- oder Taschengeldjob
                erstellen möchtest.
			</small>
		</h3>
		<RegisterPage update={update} />
	</Container>
)

const Login = () => (
	<Container>
		<h1>Login</h1>
		<h3>
			<small className='text-muted'>
                Registriere dich, wenn du einen Ferien- oder Taschengeldjob
                erstellen möchtest. Anonyme Einträge kannst du auch über den{' '}
				<Link
					className='p-1 badge text-dark bg-primary text-decoration-none'
					to='/impressum'
				>
                    Jugendtreff
				</Link>{' '}
                erstellen lassen.
			</small>
		</h3>

		<LoginPage />
	</Container>
)
const About = () => (
	<Container>
		<h1>Verbinde dich mit talentierten Dienstleistern</h1>
		<HomePage />
	</Container>
)
const Settings = ({ uid, displayName }) => (
	<Container>
		<h1>Einstellungen</h1>
		<SettingsPage uid={uid} displayName={displayName} />
	</Container>
)

export default function App () {
	const [{ uid, displayName }, setUser] = useState({
		uid: false,
		displayName: ''
	})
	useEffect(() => {
		auth.onAuthStateChanged(user =>
			setUser(user || { uid: false, displayName: '' })
		)
	}, [setUser])

	return (
		<BrowserRouter>
			<div className='container-fluid'>
				<div className='text-center'>
					<img className='img-fluid' src={Logo} alt='Logo' />
				</div>
				<Navigation uid={uid} displayName={displayName} />
				<main
					role='main'
					className='shadow-sm pt-3 pb-3 container-fluid'
				>
					<Routes>
						<Route
							exact
							path='/impressum'
							element={<Impressum />}
						/>
						<Route
							exact
							path='/jobs'
							element={<Jobs uid={uid} />}
						/>
						<Route
							exact
							path='/jobs/createjob'
							element={<CreateJob uid={uid} />}
						/>
						<Route
							exact
							path='/jobs/editjob/:jobId'
							element={<EditJob uid={uid} />}
						/>
						<Route
							exact
							path='/register'
							element={
								uid
									? (
										<Navigate to='/' />
									)
									: (
										<Register
											update={displayName =>
												setUser(x => ({
													...x,
													displayName
												}))
											}
										/>
									)
							}
						/>
						<Route
							exact
							path='/login'
							element={uid ? <Navigate to='/' /> : <Login />}
						/>
						<Route
							exact
							path='/settings'
							element={
								<Settings uid={uid} displayName={displayName} />
							}
						/>
						<Route index element={<About />} />
					</Routes>
				</main>
			</div>
			<Footer />
		</BrowserRouter>
	)
}
