import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { db } from '../../firebase'
import { getDoc, doc, setDoc } from 'firebase/firestore'

import Alert from '../../components/Alert'
import Input from '../../components/Input'

const validateJob = ({ email, phoneNumber }) => email || phoneNumber

export default function Edit ({ uid }) {
	const { jobId } = useParams()
	const [{ message, state }, setAlert] = useState({ message: '', state: '' })
	const [{ title, description, date, address, phoneNumber, email }, setJob] = useState({
		title: '',
		description: '',
		date: '',
		address: '',
		phoneNumber: '',
		email: ''
	})
	useEffect(() => {
		(async () => {
			const docRef = doc(db, 'jobs', jobId)
			const docSnap = await getDoc(docRef)

			if (docSnap.exists()) {
				setJob(docSnap.data())
			} else {
				setAlert({
					state: 'FAIL',
					message: 'Der Job wurde leider nicht gefunden.'
				})
			}
		})()
	}, [jobId])

	return (
		<form
			onSubmit={async event => {
				event.preventDefault()
				if (!validateJob({ phoneNumber, email })) {
					setAlert({
						state: 'FAIL',
						message:
                            'Es muss mindestens eine Kontaktierungsmöglichkeit angegeben werden.'
					})
					return
				}
				try {
					await setDoc(doc(db, 'jobs', jobId), {
						title,
						description,
						date,
						address,
						phoneNumber,
						email,
						creator: uid
					})
					setAlert({
						message: 'Spitze! Der Job wurde erfolgreich angepasst.',
						state: 'SUCCESS'
					})
				} catch (error) {
					console.error(error)
					setAlert({
						state: 'FAIL',
						message:
                            'Fehler aufgetreten. Bitte wenden Sie sich am Jugendtreff.'
					})
				}
			}}
		>
			<Input
				value={title}
				onChange={title => setJob(x => ({ ...x, title }))}
				required
				placeholder='Gib deinem Job einen Namen'
			>
                Titel
			</Input>
			<Input
				value={description}
				onChange={description => setJob(x => ({ ...x, description }))}
				required
				placeholder='Welche Tätigkeiten werden ausgeführt?'
			>
                Beschreibung
			</Input>
			<Input
				value={date}
				onChange={date => setJob(x => ({ ...x, date }))}
				required
				placeholder='Beispiel: Jeden Mittwoch von 14:00 bis 18:00 Uhr'
			>
                Wann findet der Job statt?
			</Input>
			<Input
				value={address}
				required
				onChange={address => setJob(x => ({ ...x, address }))}
				placeholder='Beispiel: Jugendtreff Abtwil'
			>
                Wo findet der Job statt?
			</Input>
			<h3 key='contactMe'>Kontaktanfrage über</h3>
			<Input
				value={phoneNumber}
				type='phone'
				onChange={phoneNumber => setJob(x => ({ ...x, phoneNumber }))}
			>
                Telefon
			</Input>
			<Input
				type='email'
				value={email}
				onChange={email => setJob(x => ({ ...x, email }))}
			>
                E-Mail
			</Input>
			<Alert state={state}>{message}</Alert>
			<div className='d-grid gap-2'>
				<button
					type='submit'
					className='btn btn-primary shadow-sm'
				>
                    Speichern
				</button>
			</div>
		</form>
	)
}
