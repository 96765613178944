import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer () {
	return (
		<footer className='footer shadow-sm mt-auto pt-3'>
			<nav className='navbar navbar-expand-lg navbar-light bg-light'>
				<div className='container-fluid'>
					<div className='navbar-nav'>
						<Link className='nav-item nav-link' to='/impressum'>
							Impressum / Kontakt
						</Link>
					</div>
				</div>
			</nav>
		</footer>
	)
}
