import React from 'react'

export default function Alert ({ state, children }) {
	switch (state) {
	case 'SUCCESS':
		return (
			<div className='mb-3'>
				<div className='mb-2 alert alert-success' role='alert'>
					{children}
				</div>
			</div>
		)
	case 'FAIL':
		return (
			<div className='mb-3'>
				<div className='mb-2 alert alert-danger' role='alert'>
					{children}
				</div>
			</div>
		)
	default:
		return null
	}
}
