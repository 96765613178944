import React from 'react'
import { Link } from 'react-router-dom'
import { auth } from '../../firebase'

export default function Navigation ({ uid, displayName }) {
	return (
		<nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
			<button
				className="navbar-toggler"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#navbarNavAltMarkup"
				aria-controls="navbarNavAltMarkup"
				aria-expanded={false}
				aria-label="Toggle navigation"
			>
				<span className="navbar-toggler-icon" />
			</button>
			<div className="collapse navbar-collapse px-4" id="navbarNavAltMarkup">
				<ul className="navbar-nav">
					<li className="nav-item">
						<Link className="nav-link" to="/">
              Home
						</Link>
					</li>
					<li className="nav-item">
						<Link className="nav-link" to="/jobs">
              Job suchen
						</Link>
					</li>
					{uid && (
						<>
							<li key="createJob" className="nav-item">
								<Link className="nav-link" to="/jobs/createjob">
                Job anbieten
								</Link>
							</li>
							<li key="user-toggle" className="nav-item dropdown">
								<div
									href="#"
									className="nav-link dropdown-toggle"
									id="navbarDropdown"
									role="button"
									data-bs-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									{displayName}
								</div>

								<div
									className="dropdown-menu"
									aria-labelledby="navbarDropdown"
								>
									<Link className="dropdown-item" to="/settings">
                  Einstellungen
									</Link>
									<Link
										className="dropdown-item"
										to="/"
										onClick={() => auth.signOut()}
									>
                  Abmelden
									</Link>
								</div>
							</li>
						</>
					)}
					{!uid && (
						<li className="nav-item">
							<Link className="nav-item nav-link" to="/login">
              Login
							</Link>
						</li>
					)}
				</ul>
			</div>
		</nav>
	)
}
