import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'

const config = {
	apiKey: 'AIzaSyAzVQLHiksjpy9vPK1c0_ZI06MfEaqD3Jo',
	authDomain: 'develop-holidayjobs.firebaseapp.com',
	databaseURL: 'https://develop-holidayjobs.firebaseio.com',
	projectId: 'develop-holidayjobs',
	storageBucket: 'develop-holidayjobs.appspot.com',
	messagingSenderId: '669246612480',
	appId: '1:669246612480:web:ee302fe7b8b08f9ef364e5'
}

const firebase = initializeApp(config)
const db = getFirestore()
const auth = getAuth()

if (process.env.NODE_ENV === 'development') {
	connectFirestoreEmulator(db, 'localhost', 3002)
	connectAuthEmulator(auth, 'http://localhost:9099/', {
		disableWarnings: true
	})
}

export { db, firebase, auth }
